import Component from '../core/Component';
import Support from '../utils/BrowserSupport';

export const STATUS = {
    OPEN: 'is-open',
    VISIBLE: 'is-visible',
};

export default class Nav extends Component {

    prepare() {
        this.element.addEventListener(Support.transitionEnd, () => {
            if (!this.is(STATUS.OPEN)){
                this.element.classList.remove(STATUS.VISIBLE);
            }
        })
    }

    open(){
        this.element.classList.add(STATUS.VISIBLE);
        this.element.offsetWidth;
        this.element.classList.add(STATUS.OPEN);
    }

    close(){
        this.element.classList.remove(STATUS.OPEN);
    }

    toggle(){
        if (this.is(STATUS.OPEN)){
            this.close()
        } else {
            this.open()
        }
    }
}