import Component from '../core/Component';

export default class ScrollToPosition extends Component {

    constructor(element) {
        super(element);
    }

    prepare() {
        let target = window.location.href.split('#')[1];
        if(target){
            let targetDoc = document.getElementById(target);
            let offset = targetDoc.offsetTop + 100;
            setTimeout(function(){
                window.scrollTo(0,offset);
                console.log('works');
            }, 100);
        }
    }
}

