import Blog from './Blog';
import Input from './Input';
import InViewport from './InViewport';
import NavToggle from './NavToggle';
import Nav from './Nav';
import Modal from './Modal';
import ModalOpener from './ModalOpener';
import Parallax from './Parallax';
import Flickity from './Flickity';
import ServicesCarousel from './ServicesCarousel';
import HeroCarousel from './HeroCarousel';
import SectionCarousel from './SectionCarousel';
import Tabs from './Tabs';
import Team from './Team';
import ScrollToPosition from './ScrollToPosition';
import Antispam from '../../components/Antispam';


const Components = {
    Blog,
    Input,
    InViewport,
    NavToggle,
    Nav,
    ModalOpener,
    Modal,
    Parallax,
    Flickity,
    ServicesCarousel,
    SectionCarousel,
    HeroCarousel,
    Tabs,
    Team,
    ScrollToPosition,
    Antispam,
};

export default Components;


