import Component from '../core/Component';
import smoothScroll from './SmoothScroll';
import ParallaxItem from './ParallaxItem';
import Flickity from 'flickity-sync';

export default class ServicesCarousel extends Component {
  constructor(element) {
    super(element);

    this.dom = {
      content: null,
      dots: [],
      slides: [],
      counter: null,
      progress: null,
      slider: null,
      name: null,
      position: null,
    };

    this.handleResize = ::this.handleResize;
  }

  prepare() {
    let isIE = /*@cc_on!@*/ false || !!document.documentMode;
    if (!isIE) {
      this.rearange();
    }

    this.flickity = new Flickity(this.dom.content, {
      cellSelector: '.services-carousel__slide',
      prevNextButtons: false,
      wrapAround: true,
      selectedAttraction: 0.1,
      friction: 0.7,
      pageDots: false,
      resize: true,
      sync: this.dom.slider,
      pauseAutoPlayOnHover: false,
      autoPlay: 7500,
    });

    this.slider = new Flickity(this.dom.slider, {
      cellSelector: '.services-carousel_nav-item',
      prevNextButtons: false,
      wrapAround: true,
      selectedAttraction: 0.1,
      friction: 0.7,
      pageDots: false,
      resize: true,
      pauseAutoPlayOnHover: false,
      draggable: true,
    });

    this.flickity.on('select', ::this.handleSelect);
    window.addEventListener('load', this.handleResize);

    this.slider.on(
      'staticClick',
      function (event, pointer, cellElement, cellIndex) {
        this.next();
      }
    );

    $(this.dom.dots).on('click', (event) => {
      this.slider.selectCell(
        this.dom.slides[this.dom.dots.indexOf(event.target)]
      );
    });

    this.flickity.stopPlayer();

    this.parallax = new ParallaxItem(this.element, {
      spread: 0,
      onViewportEnter: () => {
        this.element.classList.add('has-entered');
        this.flickity.playPlayer();
      },
    });
    smoothScroll.add(this.parallax);
  }

  shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  rearange() {
    this.array = [0, 1, 2, 3];
    this.order = this.array;

    const slides = this.dom.slides;
    for (let i = 0; i < this.dom.slides.length; i++) {
      this.dom.slides[i].outerHTML = '';
    }

    this.dom.slides = [];

    for (let i = 0; i < this.order.length; i++) {
      this.dom.slides.push(slides[this.order[i]]);
    }

    this.textContents = this.dom.content.querySelectorAll(
      '.services-carousel__slide'
    );
    this.dom.content.innerHTML = '';

    for (let i = 0; i < this.order.length; i++) {
      this.dom.slider.appendChild(slides[this.order[i]]);
      this.dom.content.appendChild(this.textContents[this.order[i]]);
    }

    this.dom.name.innerText = this.dom.slides[this.order[0]].dataset.name;
  }

  destroy() {
    this.flickity.stopPlayer();
    window.removeEventListener('load', this.handleResize);
    smoothScroll.remove(this.parallax);
  }

  handleResize() {
    this.slider.resize();
    this.flickity.resize();
  }

  handleSelect() {
    this.dom.dots.forEach((dot, index) =>
      dot.classList.toggle('is-active', index === this.flickity.selectedIndex)
    );
    this.dom.counter.innerText = `${this.flickity.selectedIndex + 1}/${
      this.flickity.slides.length
    }`;
    this.dom.progress.style.width =
      (
        ((this.flickity.selectedIndex + 1) / this.flickity.slides.length) *
        100
      ).toString() + '%';
    this.dom.name.innerText =
      this.dom.slides[this.flickity.selectedIndex].dataset.name;
    this.dom.position.innerText =
      this.dom.slides[this.flickity.selectedIndex].dataset.position;
  }
}
