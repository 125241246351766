import Component from '../core/Component';

export const STATUS = {
    FILLED: 'is-filled',
    FOCUSED: 'is-focused'
}

export default class Input extends Component {

    constructor(element) {
        super(element);

        this.dom = {
            input: null
        }
    }

    prepare() {
        this.dom.input.addEventListener('focus', ::this.handleFocus);
        this.dom.input.addEventListener('blur', ::this.handleBlur);
    }

    handleFocus() {
        this.element.classList.add(STATUS.FOCUSED);
    }

    handleBlur() {
        this.element.classList.remove(STATUS.FOCUSED);
        this.element.classList.toggle(STATUS.FILLED, this.dom.input.value.length);
    }
}