import $ from 'jquery';
import components from '../components';

const INSTANCE_KEY = '__instance';

export function loadComponents(context = document.documentElement) {
    if (!components || Object.keys(components).length === 0) {
        console.warn('V aplikaci neni zadna komponenta.');
        return;
    }

    $('[data-component]', context).each((index, element) => {
        var $element = $(element),
            componentName = $element.data('component'),
            component;

        if ($element.data(INSTANCE_KEY)) {
            console.warn('Element', $element, 'byl přeskočen, je svázán s komponentou', $element.data(INSTANCE_KEY)._name);
            return true; // continue
        }

        if (typeof components[componentName] === 'function') {
            component = new components[componentName]($element);

            console.info(`Načítám komponentu "${componentName}"`);
            component.prepare();
            $element.data(INSTANCE_KEY, component);

        } else {
            console.warn(`Konstruktor pro komponentu ${componentName} nebyl nalezen.`);
        }
    });
}

export function removeComponents(context = document.documentElement) {
    $('[data-component]', context).each((index, element) => {
        const $element = $(element);

        const component = $element.data(INSTANCE_KEY);

        if (component) {
            console.info(`Odebírám komponentu "${component._name}"`);
            component.destroy();
            $element.data(INSTANCE_KEY, null);
        }
    });
}