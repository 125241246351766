import Component from '../core/Component';
import Flickity from 'flickity-sync';

export default class HeroCarousel extends Component {

    constructor(element) {
        super(element);

        this.dom = {
            content: null,
            dots: [],
            slides: [],
            name: null,
            position: null,
        }

        this.handleResize = ::this.handleResize;
    }

    prepare() {
        this.flickity = new Flickity(this.dom.content, {
            cellSelector: '.hero-carousel__slide',
            prevNextButtons: false,
            wrapAround: true,
            selectedAttraction: 0.1,
            friction: .7,
            pageDots: false,
            resize: true,
            pauseAutoPlayOnHover: true,
            autoPlay: 5000,
        });

        this.flickity.on('select', ::this.handleSelect);
        window.addEventListener('load', this.handleResize);
        
        this.flickity.on('staticClick', function(event, pointer, cellElement, cellIndex){
            this.next();
        });

        $(this.dom.dots).on('click', (event)=> {
            this.flickity.selectCell(this.dom.slides[this.dom.dots.indexOf(event.target)]);
        });
    }

    destroy() {
        this.flickity.stopPlayer();
        window.removeEventListener('load', this.handleResize);
    }

    handleResize() {
        this.flickity.resize();
    }

    handleSelect() {
        this.dom.dots.forEach((dot, index) => dot.classList.toggle('is-active', index === this.flickity.selectedIndex));

        this.dom.name.innerText = this.dom.slides[this.flickity.selectedIndex].dataset.name;
        this.dom.position.innerText = this.dom.slides[this.flickity.selectedIndex].dataset.position;
    }
}

