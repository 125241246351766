import Component from '../core/Component';
import Flickity from 'flickity-sync';
import ParallaxItem from './ParallaxItem';
import SmoothScroll from './SmoothScroll';

export default class SectionCarousel extends Component {

    constructor(element) {
        super(element);

        this.dom = {
            content: null,
            prevButton: null,
            nextButton: null
        }
        this.hasEntered = false;
    }

    prepare() {
        this.parallax = new ParallaxItem(this.element);
        this.parallax.render = (scrollTop, ratio) => {
            if (ratio > -0.4 && !this.hasEntered){
                this.element.classList.add('has-entered');
                this.hasEntered = true;
            }   
        }
        SmoothScroll.add(this.parallax);


        this.flickity = new Flickity(this.dom.content, {
            cellSelector: '.section-carousel__slide',
            cellAlign: 'left',
            prevNextButtons: false,
            pageDots: false,
            resize: true,
            freeScroll: true,
            contain: true,
            groupCells: true
        });

        this.dom.prevButton.addEventListener('click', event => {
            event.preventDefault();
            this.flickity.previous();
        });

        this.dom.nextButton.addEventListener('click', event => {
            event.preventDefault();
            this.flickity.next();
        });
    }

    destroy(){
        SmoothScroll.remove(this.parallax);
    }
}

