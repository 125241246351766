import { query, queryAll } from '../utils/dom';
import $ from 'jquery';

export default class Component {

    constructor($element) {
        this.$element = $element;
        this.element = $element[0] || $element;
        this._name = this.constructor.name;
        this._dom = {};
    }

    get dom() {
        return this._dom;
    }

    set dom(items) {
        Object.keys(items)
            .forEach(key => {
                if (Array.isArray(items[key])) {
                    let elements = queryAll(this.getRef(key.slice(0, -1), true), this.element);
                    if (elements.length === 0) {
                        elements = queryAll(this.getRef(key.slice(0, -1)), this.element)
                    }
                    this._dom[key] = elements;
                } else if (!items[key]) {
                    let element = query(this.getRef(key, true), this.element);
                    if (!element) {
                        element = query(this.getRef(key), this.element);
                    }
                    this._dom[key] = element;
                } else {
                    this._dom[key] = items[key];
                }
            });

        return this._dom;
    }

    is(state) {
        return this.element.classList.contains(state);
    }

    prepare() {
        console.warn(`Komponenta ${this._name} nemá metodu prepare().`);
    }

    destroy() {
        //console.warn('Destroy method: override me');
    }

    getElement() {
        return this.$element;
    }

    getRef(ref, prefixed = false) {
        return `[data-ref="${prefixed ? `${this._name}.` : ''}${ref}"]`;
    }

    getRefElement(ref, context = this.$element) {
        return $(`[data-ref="${ref}"]`, context);
    }

    static getFromElement(element) {
        return $(element).data('__instance');
    }

}