import Component from '../core/Component';


export default class ModalOpener extends Component {

    prepare() {
        this.element.addEventListener('click', ::this.handleClick);
    }

    handleClick(event) {
        event.preventDefault();
        
        // const offset = this.element.querySelectorAll('img')[1].getBoundingClientRect()
        // const top = offset.top
        // const left = offset.left

        const target = document.getElementById(this.element.hash.replace('#', ''));
        
        if (target) {
            const modal = Component.getFromElement(target);

            if (modal) {
                modal.open();
            }
        }
    }

}