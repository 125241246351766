import Component from '../core/Component'
import smoothScroll from './SmoothScroll'
import ParallaxItem from './ParallaxItem'

export default class InViewport extends Component {

    prepare() {
        this.parallax = new ParallaxItem(this.element, {
            spread: 0,
            onViewportEnter: () => {
                this.element.classList.add('has-entered')
            }
        });
        smoothScroll.add(this.parallax)
    }

    destroy() {
        smoothScroll.remove(this.parallax)
    }
}