import Component from '../core/Component';
import $ from 'jquery';

export default class NavToggle extends Component {

    prepare() {
        this.element.addEventListener('click', ::this.handleClick);
    }

    handleClick(event){
        event.preventDefault();
        this.element.classList.toggle('is-active');

        const navEl = document.querySelector('.nav');
        const nav = Component.getFromElement(navEl);

        if(nav){
            nav.toggle();
        }
    }
}