import $ from 'jquery';
import { loadComponents, removeComponents } from '../../core/componentUtils';

$.nette.ext('snippets', null);
$.nette.ext('snippets', {
    success: function (payload) {
        if (payload.snippets) {
            this.updateSnippets(payload.snippets, payload.append);
        }
    }
}, {
    beforeQueue: $.Callbacks(),
    afterQueue: $.Callbacks(),
    completeQueue: $.Callbacks(),
    before: function (callback) {
        this.beforeQueue.add(callback);
    },
    after: function (callback) {
        this.afterQueue.add(callback);
    },
    complete: function (callback) {
        this.completeQueue.add(callback);
    },
    updateSnippets: function (snippets, append, back) {
        var that = this;
        var elements = [];
        for (var i in snippets) {
            var $el = this.getElement(i);
            if ($el.get(0)) {
                elements.push($el.get(0));
            }
            this.updateSnippet($el, snippets[i], append, back);
        }
        $(elements).promise().done(function () {
            that.completeQueue.fire();
        });
    },
    updateSnippet: function ($el, html, append, back) {
        // Fix for setting document title in IE
        if ($el.is('title')) {
            document.title = html;
        } else {
            this.beforeQueue.fire($el);
            this.applySnippet($el, html, append, back);
            this.afterQueue.fire($el);
        }
    },
    getElement: function (id) {
        return $('#' + this.escapeSelector(id));
    },
    applySnippet: function ($el, html, append, back) {
        console.info(`Aktualizuji snippet "${$el.attr('id')}"`);
        if (!back && ($el.is('[data-ajax-append]') || ($el.attr('id') == append))) {
            $el.append(html);
        } else if (!back && $el.is('[data-ajax-prepend]')) {
            $el.prepend(html);
        } else if ($el.html() != html) {
            removeComponents($el);
            $el.html(html);
        }
        loadComponents($el);
    },
    escapeSelector: function (selector) {
        // thx to @uestla (https://github.com/uestla)
        return selector.replace(/[\!"#\$%&'\(\)\*\+,\.\/:;<=>\?@\[\\\]\^`\{\|\}~]/g, '\\$&');
    }
});
