import Component from '../core/Component';
import SmoothScroll from './SmoothScroll';
import $ from 'jquery'

export const STATUS = {
}

export default class Blog extends Component {

    constructor(element) {
        super(element);

        this.dom = {
            button: null
        }
        this.page = 2
    }

    prepare() {
        if (this.dom.button) {
            this.loadMore()

            this.dom.button.addEventListener('click', (e)=>{
                e.preventDefault();
                let hidden = document.querySelectorAll('.blogpost.not-visible')
                hidden.forEach(element => element.classList.remove('not-visible'));
                SmoothScroll.resize()
                this.loadMore()
            });
        }
    }

    loadMore(){
        const newUrl = this.dom.button.href

        let buffer = document.createElement('div')

        let self = this;
        function handleData(data){
            self.handleData(data, buffer)
        }

        $.ajax(
            {
                type: "GET",
                url: newUrl,
                success: function(data){
                    handleData(data.snippets['snippet--articles'])
                }
            }
        );
    }

    handleData(data, buffer ){
        buffer.innerHTML = data
        const arr = buffer.querySelectorAll('.blogpost')
        // const button = buffer.querySelector('.button--loading')

        const elements = [...arr]
        if(!elements.length) this.dom.button.style.display = 'none'

        const grid = document.querySelector('.blog__grid')
        elements.forEach(element => {
            element.classList.add('not-visible')
            grid.appendChild(element)
        });
        SmoothScroll.resize()
        this.changeButton()

    }

    changeButton(){
        this.page++
        let oldArray = this.dom.button.href.split('page-')[1].split('')
        oldArray.shift()
        oldArray.unshift(this.page)
        let newArray = oldArray.join('')
        let newlink = this.dom.button.href.split('page-')[0] + 'page-' + newArray
        this.dom.button.href = newlink
    }

}