import Component from '../core/Component';

export const STATUS = {
    ACTIVE: 'is-active',
    HIDDEN: 'is-hidden'
}

export default class Tabs extends Component {

    constructor(element) {
        super(element);

        this.dom = {
            tabLinks: [],
            tabPanels: [],
            tabPanes: null
        };

        this.cache = {};
        this.current = null;

        this.itemSelector = this.element.getAttribute('data-tabs-item-selector');
    }

    prepare() {
        if (this.dom.tabLinks.length < 2) {
            return;
        }

        this.dom.tabLinks.forEach(element => element.addEventListener('click', ::this.handleClick));

        this.dom.tabPanels.forEach(panel => {
            if (panel.id) {
                this.cache[panel.id] = {
                    panel: panel
                };
            }
        });

        this.dom.tabLinks.forEach(anchor => {
            this.cache[anchor.getAttribute('href').replace('#', '')].anchor = anchor;
        });

        const panel = this.element.querySelector('[data-ref="tabPanel"].is-active');

        if (panel) {
            this.current = panel.id;
        }
    }

    handleClick(event) {
        event.preventDefault();
        this.show(event.target.getAttribute('href').replace('#', ''));
    }

    show(id) {
        if (id === this.current || !(id in this.cache)) {
            return;
        }

        this.cache[this.current].anchor.classList.remove(STATUS.ACTIVE);
        this.cache[id].anchor.classList.add(STATUS.ACTIVE);

        this.cache[this.current].panel.classList.add(STATUS.HIDDEN);
        this.cache[this.current].panel.classList.remove(STATUS.ACTIVE);

        this.cache[id].panel.classList.remove(STATUS.HIDDEN);
        this.cache[id].panel.classList.add(STATUS.ACTIVE);

        // Tween.to(this.dom.tabPanes, .3, {
        //     height: this.cache[id].panel.offsetHeight
        // });

        // if (this.itemSelector) {
        //     const items = this.cache[id].panel.querySelectorAll(this.itemSelector);

        //     Tween.staggerFromTo(items, 1, {
        //         opacity: 0,
        //         y: 10
        //     }, {
        //         opacity: 1,
        //         y: 0,
        //         ease: Expo.easeOut
        //     }, .02);
        // }

        this.current = id;
    }
}