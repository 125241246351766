import Component from '../core/Component';
import ParallaxItem from './ParallaxItem';
import SmoothScroll from './SmoothScroll';
import { queryAll } from '../utils/dom';

export const STATUS = {
    ACTIVE: 'is-active',
    CLOSED: 'is-closed',
    REVERSED: 'is-reversed'
}

export default class Team extends Component {
    constructor($element) {
        super($element);

        this.dom = {
            anchors: [],
            panels: []
        }

        this.cache = {}
        this.current = ''

        this.dom.anchors.forEach(anchor => {
            const id = anchor.hash.replace('#', '')

            this.cache[id] = {}
            this.cache[id].anchor = anchor
        })

        this.dom.panels.forEach(panel => {
            this.cache[panel.id].panel = panel

            if (panel.classList.contains(STATUS.ACTIVE)) {
                this.current = panel.id
            }
        })

        this.items = [];

        this.percentage = 0;
        this.offset = null;

        this.direction = -1;

    }

    prepare() {
        this.dom.anchors.forEach(element => element.addEventListener('click', ::this.handleClick))
        this.refresh()

        this.parallax = new ParallaxItem(this.element);
        this.parallax.render = ::this.render;

        SmoothScroll.add(this.parallax);
    }

    handleClick(event) {
        event.preventDefault()
        this.show(event.target.hash.replace('#', ''))
    }

    show(next) {
        if (next === this.current) {
            return
        }

        const current = this.current

        this.cache[current].panel.classList.add(STATUS.CLOSED)
        this.cache[next].panel.classList.add(STATUS.REVERSED)
        this.cache[next].panel.classList.add(STATUS.CLOSED)
        this.cache[current].panel.offsetWidth

        setTimeout(() => {
            this.cache[current].panel.classList.remove(STATUS.ACTIVE)
            this.cache[current].anchor.classList.remove(STATUS.ACTIVE)

            this.cache[next].panel.classList.add(STATUS.ACTIVE)
            this.cache[next].anchor.classList.add(STATUS.ACTIVE)
            this.cache[next].panel.offsetWidth
            this.cache[next].panel.classList.remove(STATUS.CLOSED)

            this.current = next

            setTimeout(() => {
                this.cache[next].panel.classList.remove(STATUS.REVERSED)
            }, 800)

            this.refresh()
        }, 800)

    }

    refresh() {
        const items = queryAll('.team-grid__item', this.cache[this.current].panel)
        this.items = items.filter((item, index) => index % 2 !== items.length % 2)
        this.direction = items % 2 ? -1.4 : -1

        SmoothScroll.resize()
    }

    render(scrollTop, ratio) {
        if (this.offset === null){
            this.offset = ratio;
        }
        const percentage = (ratio - this.offset)/ 2 * 100 * this.direction;

        this.items.forEach(item => item.style.transform = `translateY(${percentage}%) translateZ(0)`);
    }

    destroy(){
        SmoothScroll.remove(this.parallax);
    }
}

