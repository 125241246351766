const DISABLED = 'is-scrolling-disabled';

let offset = 0;

function getScrollTop() {
    return document.body.scrollTop || document.documentElement.scrollTop;
}

export function disableScrolling() {
    offset = getScrollTop();
    document.body.style.top = `${-offset}px`;
    document.documentElement.classList.add(DISABLED);
}

export function enableScrolling() {
    document.body.removeAttribute('style');
    document.documentElement.classList.remove(DISABLED);
    document.body.offsetWidth;
    window.scrollTo(0, offset);
}