import 'console-polyfill';
import 'es5-shim';

import FastClick from 'fastclick';
import $ from 'jquery';

// jquery utils
import 'jquery.easing';

import './plugins/nette.ajax';
import './plugins/giant.trigger';
import './plugins/giant.autosubmit';

// nette
import './nette/NetteValidationMessages';
import './nette/NetteSnippets';


import Nette from './nette/NetteForms';
window.Nette = Nette;
Nette.initOnLoad();

if ($.nette) {
    $.nette.init();
}

import SmoothScroll from './components/SmoothScroll';

import components from './components/';
import { loadComponents } from './core/componentUtils';

window.app = {
    components: components
};

FastClick.attach(document.body);
loadComponents();