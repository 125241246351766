import Component from '../core/Component';
import { enableScrolling, disableScrolling } from '../services/Viewport/scroll';
import support from '../utils/BrowserSupport';
import keycode from 'keycode';

export const STATUS = {
    ACTIVE: 'is-active',
    VISIBLE: 'is-visible',
    MODAL_ACTIVE: 'is-modal-active'
};

export default class Modal extends Component {

    constructor(element) {
        super(element);

        this.dom = {
            modalCloseButton: null,
        }

        this.handleKeyDown = ::this.handleKeyDown;
    }

    prepare() {
        this.dom.modalCloseButton.addEventListener('click', ::this.handleClick);
        this.element.addEventListener(support.transitionEnd, () => {
            if (!this.is(STATUS.ACTIVE)) {
                this.element.classList.remove(STATUS.VISIBLE);
            }
        });

        if (window.location.hash.replace('#', '') === this.element.id) {
            this.open();
        }
    }

    destroy() {
        document.removeEventListener('keydown', this.handleKeyDown);
        document.body.classList.remove(STATUS.MODAL_ACTIVE);
    }

    handleClick(event) {
        event.preventDefault();
        this.close();
    }

    handleKeyDown(event) {
        if (keycode(event.keyCode) === 'esc') {
            this.close();
        }
    }

    open() {
        // #todo dodělat otevírání obrázku z místa kde se otevřel
        // if(left && top){ 
        //     let img = this.element.querySelector('img')
        //     img.style.left = +left + 'px';
        //     img.style.top = +top + 'px';
        // }
        disableScrolling();
        this.element.classList.add(STATUS.VISIBLE);
        this.element.offsetWidth;
        this.element.classList.add(STATUS.ACTIVE);
        document.addEventListener('keydown', this.handleKeyDown);
        document.body.classList.add(STATUS.MODAL_ACTIVE);
        window.location.hash = `#${this.element.id}`;
    }

    close() {
        enableScrolling();
        this.element.classList.remove(STATUS.ACTIVE);
        document.removeEventListener('keydown', this.handleKeyDown);
        document.body.classList.remove(STATUS.MODAL_ACTIVE);
        window.location.hash = '#!';
    }
}