import { query } from '../utils/dom';
import support from '../utils/BrowserSupport';
import enquire from 'enquire.js';
import getScrollTop from '../utils/getScrollTop';

const STATUS = {
    ACTIVE: 'is-active',
    WILL_ANIMATE: 'will-animate'
};

const defaults = {
    scrollbarContainer: document.body
}

class SmoothScroll {

    constructor(element, options) {
        this.element = element;

        this.options = {
            ...defaults,
            ...options
        }

        this.content = query('.scroll-container__content', this.element);

        this.scrollBar = document.createElement('div');
        this.scrollBar.style.width = '1px';
        this.scrollBar.style.display = 'none';

        this.scrollTop = 0;
        this.cachedScrollTop = 0;
        this.currentScrollTop = 0;

        this.enabled = false
        this.scrolling = false;

        this.height = 0;
        this.scopeHeight = 0;

        this.raf = null;

        this.items = [];

        // binded methods
        this.handleScroll = ::this.handleScroll;
        this.handleResize = ::this.handleResize;
        this.render = ::this.render;

        this.enquireHandler = {
            match: () => {
                this.attach();
                this.activateScroll();
                this.render();
            },
            unmatch: () => {
                this.deactivateScroll();
                this.detach();
            }
        }

        enquire.register('(min-width: 48em)', this.enquireHandler);

    }

    attach() {
        window.addEventListener('scroll', this.handleScroll, {
            passive: true
        });
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('orientationchange', this.handleResize);

        this.element.classList.add(STATUS.ACTIVE);
        this.element.classList.add(STATUS.WILL_ANIMATE);

        this.resize();

        this.options.scrollbarContainer.appendChild(this.scrollBar);

        this.raf = requestAnimationFrame(this.render);

    }

    detach() {
        if (this.raf) {
            cancelAnimationFrame(this.raf);
        }

        window.removeEventListener('scroll', this.handleScroll, {
            passive: true
        });
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('orientationchange', this.handleResize);

        this.element.classList.remove(STATUS.ACTIVE);
        this.element.classList.remove(STATUS.WILL_ANIMATE);
        this.element.removeAttribute('style');
        this.content.removeAttribute('style');

        this.options.scrollbarContainer.removeChild(this.scrollBar);
    }

    handleScroll() {
        this.scrollTop = getScrollTop();
        if (!this.scrolling) {
            this.scrolling = true;
            this.render();
        }

    }

    handleResize() {
        this.resize();
    }

    resize() {
        this.height = this.content.clientHeight;
        this.scopeHeight = this.element.clientHeight;
        this.scrollBar.style.height = `${this.height}px`;

        this.items.forEach(item => item.resize());
    }

    render() {
        if (!this.enabled) {
            return;
        }


        const diff = this.scrollTop - this.currentScrollTop;
        const direction = diff > 0 ? 'down' : 'up';

        if (Math.abs(diff) > 0.8) {
            this.currentScrollTop += diff * 0.09;
            this.content.style[support.transform] = `translateY(${-this.currentScrollTop}px) translateZ(0)`;

            this.items.forEach(item => item.calculate(this.currentScrollTop));

            // parallax render
            this.raf = requestAnimationFrame(this.render);
        } else {
            this.currentScrollTop = Math.round(this.currentScrollTop)
            this.content.style[support.transform] = `translateY(${-this.currentScrollTop}px) translateZ(0)`;
            this.scrolling = false;
        }


    }

    enable() {
        window.scrollTo(0, this.cachedScrollTop);

        this.scrollTop = this.cachedScrollTop;
        this.currentScrollTop = getScrollTop();

        this.enabled = true;

        this.raf = requestAnimationFrame(this.render);
    }

    disable() {
        if (this.raf) {
            cancelAnimationFrame(this.raf);
        }

        this.cachedScrollTop = getScrollTop();
        this.enabled = false;
    }

    activateScroll() {
        this.scrollBar.style.display = 'block';
        this.enable();
    }

    deactivateScroll() {
        this.disable();
        this.scrollBar.style.display = 'none';
    }

    scrollToElement(element) {
        element = query(element);

        if (element) {
            const box = element.getBoundingClientRect();
            const offset = getScrollTop();
            window.scrollTo(0, box.top + offset);
        }
    }

    scrollToTop() {
        window.scrollTo(0, 0)
    }

    scrollTo(offset) {
        if (this.enabled) {
            this.deactivateScroll();
            window.scrollTo(0, offset);
            this.activateScroll();
        } else {
            window.scrollTo(0, offset);
        }
    }

    add(item) {
        if (Array.isArray(item)) {
            this.items.push(...item);
        } else {
            this.items.push(item);
        }
    }

    remove(items) {
        if (Array.isArray(items)) {
            items.forEach(item => {
                const index = this.items.indexOf(item)

                if (index > -1) {
                    this.items.splice(index, 1)
                }
            })
        } else {
            const index = this.items.indexOf(item)

            if (index > -1) {
                this.items.splice(index, 1)
            }
        }
    }

}

const smoothScroll = new SmoothScroll(query('.scroll-container'));

window.addEventListener('load', () => {
    window.scrollTop = 0;
    smoothScroll.resize();
});

export default smoothScroll;