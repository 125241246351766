import Component from '../core/Component'
import smoothScroll from './SmoothScroll'
import ParallaxItem from './ParallaxItem'

export default class Parallax extends Component {

    constructor(element) {
        super(element)
    }

    prepare() {
        const options = {}

        if (this.element.dataset.componentSpread) {
            const spread = parseInt(this.element.dataset.componentSpread)

            if (!isNaN(spread)) {
                options.spread = spread
            }
        }

        this.parallax = new ParallaxItem(this.element, options)
        smoothScroll.add(this.parallax)
    }

    destroy() {
        smoothScroll.remove(this.parallax)
    }

}