import Component from '../core/Component'
import $ from 'jquery'
import flickity from 'flickity-sync'


export default class Flickity extends Component
{
    constructor($element)
    {
        super($element)
        this.$doc = $(document)
        this.cellSelector = '[data-flickity-item]'
        let options = this.$element.data('options')

        let defaults = {
            // options, defaults listed

            accessibility: true,
            // enable keyboard navigation, pressing left & right keys

            adaptiveHeight: false,
            // set carousel height to the selected slide

            autoPlay: false,
            // advances to the next cell
            // if true, default is 3 seconds
            // or set time between advances in milliseconds
            // i.e. `autoPlay: 1000` will advance every 1 second

            cellAlign: 'left',
            // alignment of cells, 'center', 'left', or 'right'
            // or a decimal 0-1, 0 is beginning (left) of container, 1 is end (right)

            cellSelector: this.cellSelector,
            // specify selector for cell elements

            contain: true,
            // will contain cells to container
            // so no excess scroll at beginning or end
            // has no effect if wrapAround is enabled

            draggable: true,
            // enables dragging & flicking

            dragThreshold: 15,
            // number of pixels a user must scroll horizontally to start dragging
            // increase to allow more room for vertical scroll for touch devices
            // default: 3

            freeScroll: false,
            // enables content to be freely scrolled and flicked
            // without aligning cells

            friction: 0.3,
            // smaller number = easier to flick farther

            groupCells: false,
            // group cells together in slides

            initialIndex: 0,
            // zero-based index of the initial selected cell

            lazyLoad: true,
            // enable lazy-loading images
            // set img data-flickity-lazyload="src.jpg"
            // set to number to load images adjacent cells

            percentPosition: true,
            // sets positioning in percent values, rather than pixels
            // Enable if items have percent widths
            // Disable if items have pixel widths, like images

            prevNextButtons: false,
            // creates and enables buttons to click to previous & next cells

            pageDots: false,
            // create and enable page dots

            resize: true,
            // listens to window resize events to adjust size & positions

            rightToLeft: false,
            // enables right-to-left layout

            setGallerySize: true,
            // sets the height of gallery
            // disable if gallery already has height set with CSS

            watchCSS: false,
            // watches the content of :after of the element
            // activates if #element:after { content: 'flickity' }

            wrapAround: false
            // at end of cells, wraps-around to first for infinite scrolling
        }

        this.options = {
            ...defaults,
            ...options

        }

    }

    prepare() {
        this.$container = this.$element.find('[data-flickity-container]')
        if (!this.$container.length) {
            this.$container = this.$element
        }

        this.flky = new flickity( this.$container[0], this.options )
        // TODO občasné špatné nastavování výšky
        setTimeout(() => {
            this.flky.resize()
        }, 100)
        setTimeout(() => {
            this.flky.resize()
        }, 300)
        
        this.customArrows();
        
    }

    // general custom arrows
    customArrows() {
        // previous
        this.$element.find('[data-flickity-prev]').on( 'click', event => {
            event.preventDefault()
            this.flky.previous()
        })

        // next
        this.$element.find('[data-flickity-next]').on( 'click', event => {
            event.preventDefault()
            this.flky.next()
        })
    }

    parallax () {
        var self = this
        var $imgs = $(this.cellSelector + ' img');

        this.flky.on('scroll', ( event, progress ) => {
            this.flky.slides.forEach( function( slide, i ) {
                var img = $imgs[i];
                var x = ( slide.target + self.flky.x ) * -1/3;
                img.style.transform = 'translateX( ' + x  + 'px)';
            });
        });
    }

}