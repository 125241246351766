import Component from '../../js/core/Component';

export default class Antispam extends Component {
    prepare() {
        let fakeDom = document.createElement('div');
        fakeDom.innerHTML = this.element.innerText.replace(/&lt;/g, '<').replace(/&gt;/g, '>');

        // get attributes
        let key = fakeDom.querySelector('label[for="agreeemail"] strong').innerText;
        let name = fakeDom.querySelector('.anti-fill').getAttribute('name');

        // append
        let input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', name);
        input.setAttribute('value', key);
        this.element.parentNode.appendChild(input);
    }
}